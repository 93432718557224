header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 800;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  padding: 5px 20px;

  background: rgb(93, 93, 93);
  background: linear-gradient(180deg, rgba(93, 93, 93, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.header__item_left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 100%;
}

.header__item_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header__item_right > p {
  margin: 0;
  padding: 0;
  font-size: 10px;
}

.header__item_right > a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header__item_right > a > em,
p {
  font-size: 12px;
  margin: 5px;
  color: #f5f5f1;
}
