.container__featured {
  width: 100%;
  display: flex;
  margin: auto;
}

.container__featured__video {
  position: relative;
  width: 100vw;
  display: flex;
  margin: auto;
}

.container__featured__image {
  position: relative;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.container__featured__image > img {
  width: 95%;
  height: auto;
}

.container__featured__loading {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (min-width: 1325px) {
  .container__featured__video {
    width: 85vw;
  }
}
