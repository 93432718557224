.container__navbar {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container__navbar__arrow {
  color: #f5f5f1;
  font-size: 22px;
  transition: all 0.5s;
}

.arrow-up {
  transform: rotate(180deg);
}

.container__navbar > button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  color: #f5f5f1;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 12px;
}

.navbar {
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  width: 0;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.5s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar-open {
  width: 210px;
  opacity: 1;
}

.navbar > em {
  font-size: 24px;
  text-align: center;
  width: 100%;
  color: #f5f5f1;
  transform: translateY(12px);
}

.navbar > nav {
  border-top: 2px solid #f5f5f1;
  background-color: #221f1ff6;
  width: 100%;
}
.navbar > nav > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
