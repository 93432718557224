.container__footer {
  position: absolute;
  bottom: 0;

  width: 100vw;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.container__footer > p,
p > a {
  color: #c0c0c0;
  font-family: 'Poppins';
  text-decoration: none;
}

.container__footer * a {
  font-weight: bold;
  color: #e9e9e9;
}

.container__footer__icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container__footer__icons > a {
  margin: 5px;
  color: #c0c0c0;
}
