.container__playlists {
  margin: 10px 0;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  overflow-x: hidden;
}

.container__playlists_loading {
  display: flex;
  margin: auto;
  width: 100vw;
  align-items: center;
  justify-content: center;
}
