.spinner {
  width: fit-content;
  height: fit-content;
  color: #e50914;
  animation: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spinner-sm {
  font-size: 24px;
}

.spinner-md {
  font-size: 48px;
}

.spinner-lg {
  font-size: 72px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
