.container__notfound {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.container__notfound-img {
  width: 40%;
  max-width: 250px;
  min-width: 180px;
  height: auto;
}

.container__notfound-sub-text {
  font-family: 'Roboto';
  font-size: 24px;
}

.container__notfound-btn {
  margin-top: 10px;
  text-decoration: none;

  color: #f5f5f1;
  background-color: #e50914;

  border-radius: 5px;
  padding: 20px 30px;
  border: 2px solid transparent;
  box-sizing: border-box;
}

.container__notfound-btn:hover {
  border: 2px solid #f5f5f1;
}
