.container__video-modal {
  position: relative;
  width: 98vw;
  height: 90vh;

  box-sizing: border-box;

  background-color: #141414;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  font-family: 'Poppins';
}

.container__video-modal::-webkit-scrollbar {
  display: none;
}

.container__video-modal__close-btn {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 50;
}

.container__video-modal__close-btn > a {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #141414e1;
  color: #f5f5f1;
  font-size: 18px;

  display: flex;
  flex-wrap: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container__video-modal__info {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: relative;
}

.info-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.info-img > img {
  width: 100%;
  height: auto;
}

.info-img-overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.616) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.644) 100%);
}

.info-text-statistics > button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 120px;
  background-color: #f5f5f1;
  color: #141414;
  font-size: 16px;
  border-radius: 5px;
}

.info-text-statistics {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: fit-content;
  padding: 2px 5px;
  margin: 0 5px;
  border: 1px solid #f5f5f1;
}

.statistics-views,
.statistics-likes {
  background-color: #1414142c;
  color: #cccccc !important;
  font-family: 'Poppins';
}

.statistics-likes > p:nth-child(1),
.statistics-views > p:nth-child(1) {
  font-size: 16px;
  margin: 0 2px;
}

.statistics-likes > p:nth-child(2),
.statistics-views > p:nth-child(2) {
  font-size: 14px;
  margin: 0 2px;
}

.info-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
}

.info-text-date {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.info-text-date > p {
  font-size: 14px;
  font-weight: bold;
}

.info-text > h2 {
  font-size: 18px;
}

.info-text-t-series {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 10px 0;
}

.info-text-t-series > img {
  width: 18px;
  height: auto;
}

.info-text-t-series > h2 {
  font-size: 18px;
}

.line__grey,
.line__red {
  height: 3px;
  border: 0;
}

.line__grey {
  margin-top: 10px;
  background-color: #494949;
}

.line__red {
  background-color: #e50914;
}

.info-desc {
  box-sizing: border-box;
  padding: 2px;
  color: #f5f5f1;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Poppins';
  width: 100%;
  height: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: flex-start;
}
