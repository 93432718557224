.container__videoplayer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.container__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  margin: auto;
}

.iframe-responsive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
