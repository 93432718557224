* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

button {
  outline: 0;
  border: 0;
  background-color: transparent;
}

h2,
h3,
p {
  color: #f5f5f1;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  background-color: #141414;

  font-family: 'Roboto';
}

code {
  font-family: 'Roboto';
}
