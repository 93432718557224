.container_playlist {
  position: relative;
  width: 100%;
  height: auto;
  margin: 10px 0;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  scroll-snap-type: y mandatory;
}

.container__playlist-nav-buttons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  height: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: flex-start;
}

.container__playlist-nav-buttons > button {
  background-color: #313131;
  width: 28px;
  height: 6px;
  margin: 2px;
  transition: 0.5s ease-in-out;
}

.container__playlist-nav-buttons-active {
  background-color: #5f5f5f !important;
  border: 1px solid #d1d1d1;
}

.container_playlist-title {
  margin: 15px 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: fit-content;
}
.container_playlist-title > h2 {
  color: #f5f5f1;
  font-size: 12px;
  width: fit-content;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
}

.container_playlist-title__sub-text {
  margin: 5px;
  font-size: 8px;
  width: 0;
  color: #f5f5f100;
  overflow: hidden;
  display: inline-block;
  text-overflow: clip;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.container_playlist-title__sub-icon {
  color: #f5f5f1;
  font-size: 24px;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.container_playlist-title:hover > .container_playlist-title__sub-text {
  width: 100%;
  color: #f5f5f1;
  transform: translateX(0);
}

.container_playlist-title:hover > .container_playlist-title__sub-icon {
  font-size: 16px !important;
}

.container_playlist:hover > .container_playlist-title > .container_playlist-title__sub-icon {
  visibility: visible;
  font-size: 24px;
}

.container_playlist-items {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100%;
}

.container__playlist__control {
  position: absolute;
  top: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 118px;
  width: 10%;
  min-width: 25px;
  max-width: 50px;
  background-color: #221f1f81;

  color: #f5f5f1;
  font-size: 36px;
  visibility: hidden;

  cursor: pointer;
}

.control-left {
  left: 0;
  border-radius: 0 5px 5px 0;
}

.control-right {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.container_playlist:hover > .container__playlist__control {
  visibility: visible;
  z-index: 8;
}

.playlist-loading {
  background-color: #575757;
  border-radius: 15px;
  animation: flash;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 500px) {
  .container__playlist-nav-buttons {
    display: none;
  }
}

@keyframes flash {
  0%,
  100% {
    background-color: #1f1f1f;
  }
  50% {
    background-color: #292929;
  }
}

/* Scrollbar style */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  box-shadow: inset 0 0 5px grey;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177);
}
